.my-toast {
  background-color: #2f96b4 !important;
  color: white !important;
  height: 50px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.error-toast {
  background-color: rgb(112, 5, 5) !important;
  color: white !important;
  height: 50px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}